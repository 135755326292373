@import 'app.scss';
.streetGroup {
  display: grid;
  gap: spacing(4) spacing(2);

  @include from-breakpoint(md) {
    grid-template-columns: 1fr 1fr;
  }
}

.street {
  @include from-breakpoint(md) {
    grid-column: span 2;
  }
}

.cityGroup {
  display: grid;
  gap: spacing(4) spacing(2);

  @include from-breakpoint(md) {
    grid-template-columns: 1fr 2fr;
  }
}
