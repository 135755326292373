@import 'app.scss';
.fieldset {
  display: grid;
  gap: spacing(4);

  &:not(:last-child) {
    margin-bottom: spacing(8);
  }
}

.legend {
  margin-bottom: spacing(4);
}
