@import 'app.scss';
// Tweak. Copied from Vitalis
.input {
  display: none;
}

.label {
  --height: 4rem;
  --color: var(--color-fg-on-secondary-cta);
  --color-hover: var(--color-fg-on-secondary-cta);
  --bg-color: var(--color-bg-secondary-cta);
  --bg-color-hover: var(--color-bg-secondary-cta-hover);

  @include shared-input;
  @include typography-button;

  display: inline-flex;
  align-items: center;
  border-radius: calc(var(--height) / 2);
  background-color: var(--bg-color);
  min-height: var(--height);
  text-decoration: none;
  color: var(--color);

  &:hover,
  &:focus {
    background-color: var(--bg-color-hover);
    color: var(--color-hover);

    .iconContainer {
      transform: translateX(3px);
    }
  }

  &[disabled],
  [disabled] > & {
    cursor: not-allowed;
  }

  &[disabled] {
    border-color: var(--color-gray-500) !important;
    background-color: var(--color-gray-500) !important;

    &:hover {
      color: var(--color-white) !important;
    }
  }
}

.icon {
  margin-right: spacing(1);
}

.fileName {
  display: block;
  margin: spacing(1) 0 0;
}

.error {
  display: flex;
  gap: spacing(1);
  align-items: center;
  margin: spacing(1) 0 0;
  color: var(--color-error-500);
}

.helpText {
  margin: spacing(1) 0;
  line-height: 1.4;
  color: var(--color-gray-500);
  font-size: 1rem;
  font-weight: 400;
}

.errorIcon {
  flex-shrink: 0;
}
