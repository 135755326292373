@import 'app.scss';
.wrapper {
  position: relative;
}

.input {
  @include typography-paragraph-md;

  outline: none;
  border: 1px solid var(--color-gray-500);
  border-radius: var(--border-radius-base);
  box-shadow: var(--box-shadow-card);
  padding: spacing(3);
  width: 100%;
  min-height: var(--input-height);
  resize: none;

  &:not(:disabled) {
    &:hover,
    &:focus {
      border-color: var(--color-red-500);
    }
  }
}

.label {
  position: absolute;
  top: calc(var(--input-height) / 2);
  left: spacing(3);
  transform: translateY(-50%);
  transition: var(--transition-base);
  cursor: text;
  padding: 0 spacing(1);
  color: var(--color-black-300);
  font-size: 1.125rem;
  font-weight: 400;
  pointer-events: none;

  .wrapper:focus-within &,
  .wrapper.hasValue & {
    top: 0;
    background-color: var(--color-white);
    color: var(--color-black-400);
    font-size: 1rem;
  }
}

.asterix {
  color: var(--color-red-500);
}
